import { OlliePipe } from "@ollie-sports/pipe";
import shortid from "shortid";
import firebase from "firebase";
import { config } from "../config";

const sessionId = shortid();

let olliePipe: OlliePipe;
export function getOlliePipe(): OlliePipe {
  if (!olliePipe) {
    olliePipe = new OlliePipe({
      getAccountId: async () => {
        try {
          return firebase.auth()?.currentUser?.uid ?? "UNKNOWN";
        } catch (e) {
          return "UNKNOWN";
        }
      },
      deviceId: "web-browser",
      disabled: !config.olliePipeUrl,
      pipeUrl: config.olliePipeUrl,
      git: "UNKNOWN", //TODO: Add this
      enableVerboseErrorLogging: true,
      pipeFallBackUrlFirestore: config.olliePipeFallBackUrlFirestore,
      session_id: sessionId,
      source: "mobile",
      batchDelay: 3000
    });
  }
  return olliePipe;
}
